import { useState } from "react";
import './About.scss';
import useBasicTourTrans from "../util/useBasicTourTrans";
const loc = '/about';
function About() {
  const [show, setShow] = useState(false);
  const { onPageTransInDone, onPageTransOutDone } = useBasicTourTrans({
    setShow,
    name: loc,
    isLocation: loc,
    autoCompleteLoad: true
  });

  const onTransition = (e: React.TransitionEvent<HTMLDivElement>) => {
    if (e.currentTarget) {
      const opacity = window.getComputedStyle(e.currentTarget).opacity;
      if (e.propertyName === 'opacity' && opacity === '1') {
        onPageTransInDone();
      } else if (e.propertyName === 'opacity' && opacity === '0') {
        onPageTransOutDone();
      }
    }
  };
  return <div className={["about", show ? "show" : ""].join(' ')} onTransitionEnd={onTransition}>
    <div className="top-edge"></div>
    <div className="main-text">
      <h2>About this Site </h2>
    </div>
    <div className="details">
      <div className="section">
        <p>Produced by <strong><a href="https://stimulant.com" target="_blank">STIMULANT</a></strong><br /><br /></p>
        <h3>Project</h3>
        <p>
          <span className="line"><strong>Executive Producer</strong> - <span className="name">Darren David</span></span>
          <span className="line"><strong>Creative Director</strong> - <span className="name">Darren David</span></span>
          <span className="line"><strong>Design Director</strong> - <span className="name">DeEtta Cobra</span></span>
          <span className="line"><strong>Technical Director</strong> - <span className="name">Isaac Gierard</span></span>
          <span className="line"><strong>Photography Director</strong> - <span className="name">Gavin Farrell,</span> <span className="name">Michael Franz</span></span>
          <span className="line"><strong>Creative Consultant</strong> - Don Richards</span><br />
        </p>
      </div>
      <div className="section">
        <h3>WEBSITE</h3>
        <p>
          <span className="line"><strong>Producer</strong> - <span className="name">Darren David</span></span>
          <span className="line"><strong>UX Design</strong> - DeEtta Cobra</span>
          <span className="line"><strong>Visual Design</strong> - DeEtta Cobra</span>
          <span className="line"><strong>Development</strong> - <span className="name">Isaac Gierard</span></span>
          <span className="line"><strong>Content Development</strong> - <span className="name">Darren David</span></span>
          <span className="line"><strong>Cinematography</strong> - <span className="name">Gavin Farrell,</span> <span className="name">Michael Franz,</span> <span className="name">Ruben O'Malley</span></span>
        </p>
      </div>
      <div className="section">
        <h3>VIRTUAL TOUR</h3>
        <p>
          <span className="line"><strong>Producer</strong> - <span className="name">Darren David</span></span>
          <span className="line"><strong>Drone Photography</strong> - <span className="name">Lawrence Dennis</span>, <span className="name">Gavin Farrell,</span> <span className="name">Michael Franz,</span> <span className="name">Robert C. Sloan</span></span>
          <span className="line"><strong>3D Reconstruction & Visualization</strong> - <span className="name">Robert C. Sloan</span></span>
          <span className="line"><strong>Gigapixel Photography</strong> - <span className="name">Gavin Farrell,</span> <span className="name">Michael Franz,</span> <span className="name">Julio Perez</span></span>
          <span className="line"><strong>Additional Photography</strong> - <span className="name">Darren David</span></span>
          <span className="line"><strong>Gigapixel Post-production</strong> - Gavin Farrell</span>
          <span className="line"><strong>Website Development</strong> - <span className="name">Isaac Gierard</span></span>
        </p>
      </div>
      <div className="section">
        <h3>DOCUMENTARY</h3>
        <p>
          <span className="line"><strong>Director</strong> - <span className="name">Don Richards</span></span>
          <span className="line"><strong>Producer</strong> - <span className="name">Ruben O’Malley,</span><span className="name">Chris Allen</span></span>
          <span className="line"><strong>Executive Producer</strong> - <span className="name">Darren David</span></span>
          <span className="line"><strong>Scriptwriter</strong> - <span className="name">Don Richards</span></span>
          <span className="line"><strong>Narrator</strong> - <span className="name">Amanda del Castillo</span></span>
          <span className="line"><strong>Editor</strong> - <span className="name">Shannon Williams</span></span>
          <span className="line"><strong>Aerial Cinematography</strong> - <span className="name">Gavin Farrell,</span><span className="name">Michael Franz</span></span>
          <span className="line"><strong>Cinematography</strong> - <span className="name">Ruben O’Malley</span></span>
        </p>
        <p>
          <strong>SPECIAL THANKS TO</strong><br />
          <span className="name">Gregg Castro,</span>
          <span className="name">Dona Crowder,</span>
          <span className="name">Eric Dausman,</span>
          <span className="name">Ron Hamburger,</span>
          <span className="name">JulioPerez,</span>
          <span className="name">Emmanuel Perez,</span>
          <span className="name">Jonah Levin,</span>
          <span className="name">Ildiko Polony,</span>
          <span className="name">Katherine Et-Hokin,</span>
          <span className="name">Alex Cherian,</span>
          <span className="name">Fabiola Franco,</span>
          <span className="name">Leann James,</span>
          <span className="name">Raul Velez,</span>
          <span className="name">Kristen Bole,</span>
          <span className="name">Oberon Strong</span>
        </p>
        <p>
          <strong>FOOTAGE COURTESY OF</strong><br />
          <span className="name">KGO,</span>
          <span className="name">KPIX,</span>
          <span className="name">KRON,</span>
          <span className="name">Bay Area Television Archive,</span>
          <span className="name">San Francisco Public Library,</span>
          <span className="name">Archival Photography,</span>
          <span className="name">SF Public Library History Center</span>
        </p>
      </div>
      <div className="section">
        <h3>MEDIA ARCHIVE</h3>
        <p>
          <span className="line"><strong>Producer</strong> - <span className="name">Darren David</span></span>
          <span className="line"><strong>Visual Design</strong> - <span className="name">DeEtta Cobra</span></span>
          <span className="line"><strong>Development</strong> - <span className="name">Isaac Gierard</span></span>
        </p>
      </div>
      <div className="section">
        <h3>Sutro Tower Team</h3>
        <p>
          {/* <span className="line"><strong>Vice-President &amp; Chief Operating Officer</strong> - <span className="name">Raul Velez</span></span>
          <span className="line"><strong>Vice-President &amp; Chief Operating Officer, retired</strong>- <span className="name">Eric Dausman</span></span> */}
          <span className="line"><strong>VP &amp; COO</strong> - <span className="name">Raul Velez</span></span>
          <span className="line"><strong>VP &amp; COO, retired</strong>- <span className="name">Eric Dausman</span></span>
          <span className="line"><strong>Accountant</strong> - <span className="name">Susan Rengstorff</span></span>
          <span className="line"><strong>Administrative Assistant</strong> - <span className="name">Allison Ayers</span></span>
          <span className="line"><strong>Tower Crew</strong> - <span className="name">Julio Perez,</span> <span className="name">Manny Perez,</span> <span className="name">Isaac Colin</span></span>
          <span className="name">Jim Coffman</span><br />
          <span className="name">Tim Wadleigh</span><br />
          <span className="name">Marty Acuff</span><br />
          <span className="name">Kelly Quan</span><br />
          <span className="name">Peter Eckmann</span><br />
          <span className="name">Victor Blanco</span><br />
        </p>
        <p>FullWave Tower and Broadcast</p>
        <p>PalAmerican Security</p>
      </div>

      <div className="section min-span">
        <h3>KGO-TV</h3>
        <p>
          <span className="line"><strong>Producer</strong> - <span className="name">Ken Miguel</span></span>
          <span className="line"><strong>Host</strong> - <span className="name">Amanda del Castillo</span></span>
          <span className="line"><span className="name">Tom Cibrowski</span></span>
        </p>
      </div>

      <div className="section">
        <h3>Simpson Gumpertz &amp; Heger</h3>
        <p>
          <span className="name">Ronald O. Hamburger, P.E., S.E.</span><br />
          <span className="name">Benjamin J. Weaver, S.E</span><br />
          <span className="name">Kevin S. Moore, P.E., S.E.</span>
        </p>
      </div>

      <div className="section">
        <h3>Lighthouse Public Affairs</h3>
        <p>
          <span className="name">Taylor Jordan</span><br />
          <span className="name">Boe Hayward</span><br />
          <span className="name">Veronica Bell</span>
        </p>
      </div>

      <div className="section">
        <h3>Womble Bond Dickinson (US) LLP</h3>
        <p>
          <span className="name">Kristen Thall Peters</span><br />
          <span className="name">Mark Warnke</span><br />
          <span className="name">Walter Hansell</span><br />
          <span className="name">Jamie Chou</span>
        </p>
      </div>

      <div className="section">
        <h3>Special Thanks</h3>
        <p>
          <span className="line">Twin Peaks Improvement Association &amp; Dona Crowder and Kathryn Goldman Schuyler</span>
          <span className="line">Midtown Terrace Home Owners Association &amp; Siu Ling Chen and George Wooding</span>
          <span className="line">Forest Knolls Neighborhood Organization &amp; Walter Caplan</span>
          <span className="line">The City and County of San Francisco</span>
          <span className="line">San Francisco Planning Department</span>
          <span className="line">Mayor London Breed</span>
          <span className="line">Supervisor Myrna Melgar, District 7</span>
          <span className="line">San Francisco Public Library</span>
          <span className="line">Department of Emergency Management</span>
          <span className="line">San Francisco Police Department Park Police Station &amp; Officer Ron Quock</span>
          <span className="line">Vincent Woo</span>
          <span className="line">Karl the Fog</span>
        </p>
      </div>

    </div>

  </div >;
}
export default About;