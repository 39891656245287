import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import Layout from './Layout';
import CookieConsentInit from './util/cookieconsent';
CookieConsentInit();
window.sttDebug = window.location.host !== 'explore.sutrotower.com';
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Layout />
  </React.StrictMode>,
);
