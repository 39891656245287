import { useContext, useEffect } from "react";
import NavigationContext, { NavigationInterceptorResult } from "../contexts/NavigationContext";
import { useLocationContextWithContext } from "./useLocationContext";

type UseBasicTourTransOptions = {
  isLocation: string | ((loc: string) => boolean),
  setShow: (show: boolean) => void,
  startLoad?: () => void,
  autoCompleteLoad?: boolean
  name: string
};
export default function useBasicTourTrans({
  isLocation,
  setShow,
  startLoad,
  autoCompleteLoad,
  name
}: UseBasicTourTransOptions) {
  const isLoc = typeof isLocation === "string" ? (loc: string) => loc === isLocation : isLocation;
  const navContext = useContext(NavigationContext);
  const { navManager } = navContext;
  const { pendingLocation, currentLocation, isInitialLoad, hasTransitionedOut } = useLocationContextWithContext(navContext);

  const completeLoad = () => navManager.navigationActor.send({ type: 'COMPLETE_LOAD' });

  useEffect(() => {
    const toIds: number[] = [];
    if (pendingLocation && isLoc(pendingLocation)) {
      toIds.push(setTimeout(() => navManager.navigationActor.send({ type: 'START_LOAD' }), 10));
      startLoad ? startLoad() : null;
      if (autoCompleteLoad) {
        toIds.push(setTimeout(completeLoad, 20));
      }
    }
    if (isLoc(currentLocation) && pendingLocation && !isLoc(pendingLocation)) {
      navManager.navigationActor.send({ type: 'START_OUT_TRANSITION' });
      setShow(false);
    }
    const intercept = (to: string) => {
      if (isLoc(to)) {
        return NavigationInterceptorResult.Immediate;
      }
      return NavigationInterceptorResult.Pass;
    };
    navManager.addIntercept(name, intercept);
    return () => {
      navManager.removeIntercept(name);
      toIds.forEach((id) => clearTimeout(id));
    };
  }, [currentLocation, pendingLocation, navManager]);

  useEffect(() => {
    if (pendingLocation && isLoc(pendingLocation) && (hasTransitionedOut)) {
      navManager.navigationActor.send({ type: 'START_IN_TRANSITION' });
      setShow(true);
    }
  }, [pendingLocation, isInitialLoad, hasTransitionedOut, navManager]);

  const onPageTransOutDone = () => {
    const chapter = document.querySelector('.tour .tour-chapter.history');
    if (chapter) {
      chapter.scrollTop = 0;
    }
    navManager.navigationActor.send({ type: 'COMPLETE_OUT_TRANSITION' });
  };
  const onPageTransInDone = () => {
    navManager.navigationActor.send({ type: 'COMPLETE_IN_TRANSITION' });
  };
  return { navContext, onPageTransInDone, onPageTransOutDone, completeLoad };
}