import { createContext } from "react";
import { ActorRefFrom, createActor } from "xstate";
import NavigationMachine from "../xstate/machines/NavigationMachine";
import { History, createBrowserHistory } from "history";
import sitePages from '../site-structure.json';
const allPagePaths = sitePages.map(v => v.path);
const pathToTitle = sitePages.reduce((m, v) => {
  if (v.title) {
    m[v.path] = v.title;
  }
  return m;
}, {} as { [id: string]: string });
// const pathToObj = sitePages.reduce((m, v) => {
//   if (v.title) {
//     m[v.path] = v;
//   }
//   return m;
// }, {} as { [id: string]: any });

const siteTree = sitePages.reduce((m, v) => {
  const chain = v.path.split('/').slice(1);
  if (chain[0] === '') {
    // root node
    m['page'] = v;
    return m;
  }
  let c = m;
  for (let i = 0, l = chain.length; i < l; i++) {
    const name = chain[i];
    if (!('children' in c)) {
      c.children = {};
    }
    if (!(name in c.children)) {
      c.children[name] = { children: {} };
    }
    if (i + 1 === l) {
      c.children[name].page = v;
    } else {
      c = c.children[name];
    }
  }
  return m;
}, { children: {}, page: null } as { [id: string]: any });

function findParentData(page: string) {
  const path = page.split('/').slice(1);
  let og_title = siteTree.page['og_title'];
  let og_description = siteTree.page['og_description'];
  let og_image = siteTree.page['og_image'];
  let c = siteTree;
  if (path[0] !== '') {
    for (let i = 0, l = path.length; i < l; i++) {
      const name = path[i];
      const entry = c.children[name];
      if (!entry || !entry.page) {
        break;
      }
      const page = entry.page;
      og_title = page['og_title'] || og_title;
      og_description = page['og_description'] || og_description;
      og_image = page['og_image'] || og_image;
      c = entry;
    }
  }
  return { og_title, og_image, og_description };
}




const history = createBrowserHistory();
type NavigationMachineType = typeof NavigationMachine;
const navigationActor = createActor(NavigationMachine, {
  input: {
    currentLocation: '',
    pendingLocation: undefined
  }
});
export enum NavigationInterceptorResult {
  Pass,
  Queue,
  Immediate
}

function log(...args: any[]) {
  window.sttDebug && console.log(...args);
}

export type NavigationInterceptor = (to: string) => NavigationInterceptorResult;
export class NavigationManager {
  pendingNavigationRequest: string;
  pendingNavigationRequestWasBack: boolean;
  navigationActor: ActorRefFrom<NavigationMachineType>;
  history: History;
  interceptors: { [name: string]: NavigationInterceptor } = {};
  navLocks = new Set<string>();
  private _wasViewing: boolean = false;
  private _lastViewingLocation: string = '';
  private _initialNavComplete: boolean;

  constructor(navigationActor: ActorRefFrom<NavigationMachineType>, history: History) {
    this.navigationActor = navigationActor;
    this.history = history;
    this.pendingNavigationRequest = '';
    this.pendingNavigationRequestWasBack = false;
    this._initialNavComplete = false;
    // strip trailing slash
    if (this.history.location.pathname.match(/.\/$/)) {
      this.history.replace(this.history.location.pathname.replace(/\/$/, ''));
    }
    this.navigationActor.subscribe((snapshot) => {
      log('navContext', JSON.stringify(snapshot.value));
      const viewing = snapshot.matches('viewing');
      if ((!this._wasViewing && viewing) ||
        // OR if we received an immediate location update (sub url of sub page)
        (viewing && this._lastViewingLocation != snapshot.context.currentLocation)
      ) {
        const title = pathToTitle[snapshot.context.currentLocation];
        if (title) {
          document.title = `${title} - Explore Sutro Tower`;
        } else {
          document.title = `Explore Sutro Tower`;
        }

        const { og_description, og_image, og_title } = findParentData(snapshot.context.currentLocation);
        const title_el = document.querySelector('meta[property="og:title"]');
        title_el && title_el.setAttribute('content', og_title);
        const description_el = document.querySelector('meta[property="og:description"]');
        description_el && description_el.setAttribute('content', og_description);
        const image_el = document.querySelector('meta[property="og:image"]');
        image_el && image_el.setAttribute('content', og_image);

        this._lastViewingLocation = snapshot.context.currentLocation;
        if (!snapshot.context.currentLocationWasBack && this._initialNavComplete) {
          this.forceURLPush(snapshot.context.currentLocation);
          // if (window.gtag) {
          //   log('send GA page_view event');
          //   window.gtag('event', 'page_view');
          // }
        }
        const pendingRequest = this.pendingNavigationRequest;
        const pendingRequestWasBack = this.pendingNavigationRequestWasBack;
        this.pendingNavigationRequest = '';
        this.pendingNavigationRequestWasBack = false;
        this._initialNavComplete = true;
        if (pendingRequest) {
          this.requestNavigation(pendingRequest, pendingRequestWasBack);
        }
      }
      this._wasViewing = viewing;
    });
    this.navigationActor.start();
    this.navigationActor.send({ type: 'START', to: this.history.location.pathname.replace(/.\/$/, '') });
    this.history.listen((update) => {
      if (update.action === 'POP') {
        if (this._lastViewingLocation !== update.location.pathname) {
          this.requestNavigation(update.location.pathname, true);
        }
      }
    });
  }
  addLock(id: string) {
    this.navLocks.add(id);
    // console.log('add', this.navLocks);
  }
  removeLock(id: string) {

    // don't do anything if locks already empty
    if (!this.navLocks.size) {
      return;
    }

    this.navLocks.delete(id);
    // // if locks newly empty retry last requested nav
    // if (!this.navLocks.size && this.pendingNavigationRequest) {
    //   this.requestNavigation(this.pendingNavigationRequest, this.pendingNavigationRequestWasBack);
    // }
    // // console.log('rm', this.navLocks);
  }

  addIntercept(key: string, intercept: NavigationInterceptor) {
    this.interceptors[key] = intercept;
  }
  removeIntercept(key: string) {
    delete this.interceptors[key];
  }
  requestNavigation(to: string, back: boolean = false): boolean {
    log(`request nav to: ${to}`);
    const snap = this.navigationActor.getSnapshot();
    const currentPendingLocation = snap.context.pendingLocation;
    if (to === currentPendingLocation) {
      log(`double requesting nav to ${to}`);
      return true;
    }

    const iResults = Object.values(this.interceptors).map(v => v(to));
    const queue = iResults.some(v => v === NavigationInterceptorResult.Queue);

    if (queue || this.navLocks.size > 0) {
      this.pendingNavigationRequest = to;
      this.pendingNavigationRequestWasBack = back;
      log('queueing nav to ', to);
      return false;
    }
    const immediate = iResults.some(v => v === NavigationInterceptorResult.Immediate);
    if (immediate) {
      this.navigationActor.send({ type: 'IMMEDIATE_CHANGE', to, wasBack: back });
    } else {
      const fromPage = snap.context.currentLocation;

      this.navigationActor.send({ type: 'NAV_REQUEST', to, wasBack: back });
      if (!allPagePaths.find(v => v === to)) {
        window.sttDebug && console.error(`navigating to missing page ${to}`);
        this.navigationActor.send({ type: "START_LOAD" });
        this.navigationActor.send({ type: "COMPLETE_LOAD" });
        this.navigationActor.send({ type: "START_IN_TRANSITION" });
        this.navigationActor.send({ type: "COMPLETE_IN_TRANSITION" });
      }
      if (!allPagePaths.find(v => v === fromPage)) {
        window.sttDebug && console.error(`navigating from missing page ${fromPage}`);
        this.navigationActor.send({ type: "START_OUT_TRANSITION" });
        this.navigationActor.send({ type: "COMPLETE_OUT_TRANSITION" });
      }
    }

    return true;
  }
  forceURLReplace(to: string) {
    this.history.replace(to);
  }
  forceURLPush(to: string) {
    this.history.push(to);

  }
}
const navManager = new NavigationManager(navigationActor, history);

export type NavigationContextType = {
  history: History,
  navManager: NavigationManager
};
const NavigationContext = createContext<NavigationContextType>({
  history,
  navManager
});

export default NavigationContext;