import isIos from "./isIos";

type VideoLevelInfo = {
  cutoff: number,
  hlsLevel: number,
  res: Array<number>
};

let maxTextureSize = -1;
function getMaxTexSize() {
  while (maxTextureSize == -1) {
    const element = document.getElementById('gl-spy');
    if (!element) { maxTextureSize = 1024; break; }
    const canvas: HTMLCanvasElement = element as HTMLCanvasElement;
    const gl = canvas.getContext('webgl');
    if (!gl) { maxTextureSize = 1024; break; }
    const maxTex = gl.getParameter(gl.MAX_TEXTURE_SIZE);
    maxTextureSize = maxTex;
    element.remove();
    break;
  }
  return maxTextureSize;
}
export function resolutionSelection(VideoLevels: Array<VideoLevelInfo>, iosMaxOffset: number = 3) {
  // function GetRenderLevelAndResolution() {
  //   getMaxTexSize();
  //   const maxResEdge = Math.max(VideoLevels[0].res[0], VideoLevels[0].res[1]);
  //   const maxWindowSize = Math.max(
  //     window.devicePixelRatio * (window.visualViewport?.width || maxResEdge),
  //     window.devicePixelRatio * (window.visualViewport?.height || maxResEdge)
  //   );
  //   const sortedVideoLevelsHiLo = VideoLevels.sort((a, b) => b.cutoff - a.cutoff);
  //   let targetVideoLevel = sortedVideoLevelsHiLo[0];
  //   for (const k of sortedVideoLevelsHiLo) {
  //     if (maxWindowSize > k.cutoff) {
  //       targetVideoLevel = k;
  //       break;
  //     }
  //   }


  //   // this seems unlikly to happen but...
  //   while (
  //     // if the target res is larger than the max tex in either direction
  //     (targetVideoLevel.res[0] > maxTextureSize || targetVideoLevel.res[1] > maxTextureSize)
  //     && (VideoLevels.indexOf(targetVideoLevel) + 1) < VideoLevels.length) {
  //     targetVideoLevel = VideoLevels[VideoLevels.indexOf(targetVideoLevel) + 1];
  //   }

  //   // limit iOS to the bottom two levels
  //   if (isIos && VideoLevels.indexOf(targetVideoLevel) < VideoLevels.length - iosMaxOffset) {
  //     targetVideoLevel = VideoLevels[Math.max(0, VideoLevels.length - iosMaxOffset)];
  //   }

  //   return {
  //     level: targetVideoLevel.hlsLevel, resolution: targetVideoLevel.res
  //   };
  // }

  function GetVideoLevelAndResolution() {
    getMaxTexSize();
    const maxResEdge = Math.max(VideoLevels[0].res[0], VideoLevels[0].res[1]);
    const maxWindowSize = Math.max(
      window.devicePixelRatio * (window.visualViewport?.width || maxResEdge),
      window.devicePixelRatio * (window.visualViewport?.height || maxResEdge)
    );
    let targetVideoLevel = VideoLevels[0];
    const sortedVideoLevelsHiLo = VideoLevels.sort((a, b) => b.cutoff - a.cutoff);
    for (const k of sortedVideoLevelsHiLo) {
      if (maxWindowSize > k.cutoff) {
        targetVideoLevel = k;
        break;
      }
    }


    // this seems unlikly to happen but...
    while (
      // if the target res is larger than the max tex in either direction
      (targetVideoLevel.res[0] > maxTextureSize || targetVideoLevel.res[1] > maxTextureSize)
      && (sortedVideoLevelsHiLo.indexOf(targetVideoLevel) + 1) < sortedVideoLevelsHiLo.length) {
      targetVideoLevel = sortedVideoLevelsHiLo[sortedVideoLevelsHiLo.indexOf(targetVideoLevel) + 1];
    }

    // limit iOS to the bottom two levels
    if (isIos && sortedVideoLevelsHiLo.indexOf(targetVideoLevel) < sortedVideoLevelsHiLo.length - iosMaxOffset) {
      targetVideoLevel = sortedVideoLevelsHiLo[Math.max(0, sortedVideoLevelsHiLo.length - iosMaxOffset)];
    }

    return {
      level: targetVideoLevel.hlsLevel, resolution: targetVideoLevel.res
    };
  }
  return { GetRenderLevelAndResolution: GetVideoLevelAndResolution, GetVideoLevelAndResolution };
}