export const lerp = (a: number, b: number, alpha: number) => {
  return a + alpha * (b - a);
};
function normAngle(angle: number) {
  while (angle < 0)
    angle += 360;
  while (angle >= 360)
    angle -= 360;
  return angle;
}
// https://gamedev.stackexchange.com/questions/72348/how-do-i-lerp-between-values-that-loop-such-as-hue-or-rotation
export function lerpShortestAngle(startAngle: number, endAngle: number, alpha: number) {
  const dForward = endAngle - startAngle;
  const dBackward = startAngle - endAngle;
  if (normAngle(dForward) < normAngle(dBackward)) {
    if (endAngle < startAngle) {
      endAngle += 360;
    }
  } else {
    if (endAngle > startAngle) {
      endAngle -= 360;
    }
  }
  return normAngle(lerp(startAngle, endAngle, alpha));
}
export const TO_RAD = Math.PI / 180;
export const FROM_RAD = 180 / Math.PI;